<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Users ({{count}}) </h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
					v-if="config.getPermission('user').create"
					size="sm" style="margin-right: 10px" 
					color="success" @click="toggleModal()" 
					class="float-lg-right"><i class="fa fa-plus"></i>Add User
					</CButton>
					<CButton 
					v-if="config.getPermission('user').download"
					size="sm" style="margin-right: 10px" 
					color="btn btn-outline-primary" @click="download()" 
					class="float-lg-right"><i class="fa fa-download"></i>Download
					</CButton>
		      		
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	     
		      <CCol lg="12">  
				  <div class="table-responsive-sm table-responsive-md">
	      		<CTabs variant="pills" :active-tab="0">
				    <CTab title="All" @click.native="setStatus('')">
				    	<br/>
						
				      	<CDataTable
						    :loading="isLoading"
					      	:items="dataListAll.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
					    >  
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}
								</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('user').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('user').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('user').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem>  
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageAll"
				          	:pages="Math.ceil(dataListAll.total / 20)"
				          	:activePage="currentPageAll"
				          	@update:activePage="updatePageAll"
				        />
				    </CTab>
				    <CTab title="Active" @click.native="setStatus('active')">
				    	<br/>
						
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListActive.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
							
					    >  
					    <template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}
								</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('user').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('user').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('user').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageActive"
				          	:pages="Math.ceil(dataListActive.total / 20)"
				          	:activePage="currentPageActive"
				          	@update:activePage="updatePageActive"
				        />
				    </CTab> 
				    <CTab title="Inactive" @click.native="setStatus('inactive')">
				    	<br/>
						
				      	<CDataTable
						  :loading="isLoading"
					      	:items="dataListInactive.data"
					      	:fields="fields"
							hover
							striped
							outlined
							
							
					    >  
					    	<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}
								</CBadge>
         						 </td>
							</template>
					      	<template #action="{item}">
						        <td style="padding: 5px; width: 100px">
						        	<CDropdown 
						                toggler-text="Select"  
										size="sm"
						                color="info"
						            >
						            	<CDropdownItem v-if="config.getPermission('user').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem> 
						                <CDropdownItem v-if="config.getPermission('user').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit </i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('user').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete </i></CDropdownItem> 
						            </CDropdown>
						        </td>
					      	</template>
					    </CDataTable> 
					    <CPagination
				          	:active-page.sync="currentPageInactive"
				          	:pages="Math.ceil(dataListInactive.total / 20)"
				          	:activePage="currentPageInactive"
				          	@update:activePage="updatePageInactive"
				        />
				    </CTab> 
				</CTabs> 
				  </div>
	      	</CCol>
	    </CRow>

		
		<!-- CREATE/UPDATE -->
	    <CModal :title="title" :show.sync="formModal" color="info" size="lg">
           	<form @submit.prevent="submit"> 
           		<CRow>
					   
					<CCol lg="12">
						<label class="mb-1">
							Username
							<span class="text-danger">*</span>
						</label>
						<CInput
		                  v-model="dataParams.username"
		                  required="true"
		                />
					</CCol> 
				</CRow>
				<CRow>
					<CCol lg="6">
						<label class="mb-1">
							Firstname
							<span class="text-danger">*</span>
						</label>
						<CInput
		                  
		                 
		                  v-model="dataParams.firstname"
		                  required="true"
		                />
					</CCol> 
					<CCol lg="6">
						<label class="mb-1">
							Lastname
							<span class="text-danger">*</span>
						</label>
						<CInput 
		                  
		                 
		                  v-model="dataParams.lastname"
		                  required="true"
		                />
					</CCol> 
				</CRow>
				<CRow>
					
				</CRow>
				<CRow>
					<CCol lg="12">
						<label class="mb-1">
							Email Address
							<span class="text-danger">*</span>
						</label>
						<CInput
		                
		                 
		                  v-model="dataParams.email"
		                  required="true"
		                  type="email"
		                />
					</CCol> 
				</CRow>
				<CRow>
					<CCol lg="12">
						<label class="mb-1">
						Password
							<span class="text-danger">*</span>
						</label>
						<CInput
		                
		                 
		                  v-model="dataParams.password"
		                  :required="!this.editMode"
		                  type="password"
		                />
					</CCol> 
				</CRow>  
				<CRow>
					<CCol lg="12">
						<div class="form-group"> 
						<label class="mb-1">
						Usertype
							<span class="text-danger">*</span>
						</label>
							<v-select 
								label="setting_name" 
								:options="userTypeList.data"
								:reduce="item => item.id"
								v-model="dataParams.usertype_id"
								placeholder="-Select-"
							>
								<template #search="{attributes, events}">
									<input
										class="vs__search"
										:required="!dataParams.usertype_id"
										v-bind="attributes"
										v-on="events"
									/>
								</template>
							</v-select>
						</div> 
					</CCol> 
				</CRow>  
				<CRow>
					<CCol lg="12">
						<div class="form-group"> 
							<label class="mb-1">Depot</label>
							<v-select 
								:disabled="depot_id != null"
								label="setting_name" 
								:options="depotList.data"
								:reduce="item => item.id"
								v-model="dataParams.depot_id"
								placeholder="-Select-"
							> 
							</v-select>
						</div> 
					</CCol> 
				</CRow>   
				<CRow v-if="config.getUserDetail('usertype').setting_name == 'Pahrsek Admin'">
					<CCol lg="12">
						<div class="form-group"> 
							<label class="mb-1">Company</label>
							<v-select 
								label="label" 
								:options="companyList.data"
								:reduce="item => item.id"
								v-model="dataParams.company_id"
								placeholder="-Select-"
							> 
							</v-select>
						</div> 
					</CCol> 
				</CRow>   
				<CRow> 
					<CCol lg="12">
						<div class="form-group">
							<label for="employment_type" class="mb-1">
								Status
							<span class="text-danger">*</span>
						</label>
							
							<CInputRadioGroup  
			                    :options="[
			                    	{ 
										value: 'active', 
										label: 'Active'
							        },
							        { 
										value: 'inactive', 
										label: 'Inactive'
							        }
			                    ]" 
			                    :checked="dataParams.status"
			                    @update:checked="updateStatus"
			                    :inline="true" 
			                    required="true"
			                />
			            </div>
					</CCol>
				</CRow>
				 
	            <div slot="footer" class="w-100">
					 <CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	            	<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	               
	                
	            </div>
				
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>

		<!-- VIEWING DETAILS -->
        <CModal title="User" :show.sync="showViewModal" color="info" size="lg"> 
			<CCard class="p-3 pl-2 pr-2 ml-2 mr-2 mt-0">
        	<!-- <div class="pl-3 pr-3 pb-3">  -->
				<CRow class="p-1 mb-2 bg-secondary text-dark">
						<CCol lg="6">
							<strong style="font-size:10px">User Information</strong>
						</CCol>
							
				</CRow>
				
        		<CRow class="mb-2">
	        		<CCol lg="4">
	        			Username:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.username}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Firstname:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.firstname}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Lastname:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.lastname}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Email Address:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.email}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Usertype:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.usertype_id ?  dataParams.usertype_id_label : "-No data-"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Depot:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.depot_id  ?  dataParams.depot_id_label : "All"}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2">
	        		<CCol lg="4">
	        			Status:
	        		</CCol>
	        		<CCol lg="8" class="text-uppercase">
	        			<strong>{{dataParams.status}}</strong>
	        		</CCol>
	        	</CRow>
	        	<CRow class="mb-2" v-if="config.getUserDetail('usertype').setting_name == 'Pahrsek Admin'">
	        		<CCol lg="4">
	        			Company:
	        		</CCol>
	        		<CCol lg="8">
	        			<strong>{{dataParams.company_id  ?  dataParams.company.company_name : "N/A"}}</strong>
	        		</CCol>
	        	</CRow>
        	<!-- </div> -->
				</CCard>
        	<!-- <div slot="footer" class="w-100">
            	<CButton style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="showViewModal = false">
                    Close
                </CButton> 
                
            </div> -->
        	<div slot="footer" class="w-100"></div>
        </CModal>
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
	mounted(){ 
		this.getData();
		this.getDataInactive();
		this.getDataActive();
		this.getVendor();
		this.getDepot();
		this.getUserType();  
			for(var i = 0; i < document.querySelectorAll('.nav-item').length; i++){
			document.querySelectorAll('.nav-item')[i].onclick = ()=>{
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}
	},
	data(){
		return{
			config,
			title:'',
			formModal: false,
			showViewModal: false,
			dataListAll: [],
			dataListInactive: [],
			dataListActive: [],
			current_tab: "All",
			count: 0,
			depotName: "",
			depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				usertype_id: "",
				depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
				username: "",
				firstname: "",
				lastname: "",
				email: "",
				password: "",
				status: "active"
			},
			fields: [
				{
					key: 'username', 
					label: 'Username'
				},
				{
					key: 'email', 
					label: 'Email Address'
				},
				{
					key: 'firstname', 
					label: 'Firstname'
				},
				{
					key: 'lastname', 
					label: 'Lastname'
				},
				{
					key: 'usertype_id_label', 
					label: 'Usertype'
				},
				{
					key: 'depot_id_label', 
					label: 'Depot'
				},
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPageAll: 1,
			currentPageInactive: 1,
			currentPageActive: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			isLoading: false,
			filter: {
				username: "",
	            email: "",
	            firstname: "",
	            lastname: "",
	            usertype_id: "", 
	            vendor_id: "",
	            depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			},
			userTypeList: {
				data: []
			},
			companyList: {
				data:[]
			},
			selected_status : ''
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
	methods: {
		setStatus(status) {
			this.selected_status = status;
		},
		download() {
			
			this.$showLoading(true)
	    	let status = this.selected_status;

			let usertype_id = this.filter.usertype_id;
	    	if(usertype_id == "all" || usertype_id == null){
	    		usertype_id = ""
	    	}
	    
			axios.get(config.api_path+'/users/report/list', {
				params : {
					status,
					email : this.filter.email,
					firstname : this.filter.firstname,
					lastname : this.filter.lastname,
					username : this.filter.username,
					usertype_id,
					depot_id : this.filter.depot_id

				}
			}).then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 
	
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);
			}).catch(err => {
				this.$showLoading(false)
				console.error(err)
			})
		},
		setCount(){
			if(this.current_tab == "All"){
				this.count = this.dataListAll.total
			}
			if(this.current_tab == "Active"){
				this.count = this.dataListActive.total
			}
			if(this.current_tab == "Inactive"){
				this.count = this.dataListInactive.total
			}
		},
		 getBadge (status) {
    	return status === 'active' ? 'success'
             : status === 'inactive' ? 'danger' : 'primary'
    },
		updatePageActive(data){
			this.currentPageActive = data;
			this.getDataActive();
		}, 
		updatePageAll(data){
			this.currentPageAll = data;
			this.getData();
		}, 
		updatePageInactive(data){
			this.currentPageInactive = data;
			this.getDataInactive();
		}, 

	    toggleModal(){
	    	this.formModal = !this.formModal;
			this.title = "Create User";

	    },

	    submit(){
	    	var ax = {};
	    	if(this.dataParams.depot_id == "" || this.dataParams.depot_id == "all"){
	    		this.dataParams.depot_id = null;
	    	} 
	    	if(this.editMode){ console.log(this.selectedRow)
	    		ax = axios.put(config.api_path+"/users/"+this.selectedRow.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/users", this.dataParams)
	    	}
	    	ax.then(response => {
	    		if(response.data.status == 'failed'){
	    			Swal.fire({
						title: 'Error!',
						text: response.data.message,
						icon: 'error', 
					})
					return;
	    		}
	    		var text = 'User successfully added!';
	    		if(this.editMode){
	    			text = 'User successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					usertype_id: "",
					depot_id: "",
					username: "",
					firstname: "",
					lastname: "",
					email: "",
					password: "",
					status: "active"
				}
	    		this.getData();
	    		this.getDataInactive();
	    		this.getDataActive();
	    	})
	    },

	    search(event){
	    	this.filter = event; 
	    	this.getData();
	    	this.getDataInactive();
	    	this.getDataActive()
	    },

	    getData(){ 

			this.isLoading = true;

	    	var usertype_id = this.filter.usertype_id;
	    	if(usertype_id == "all" || usertype_id == null){
	    		usertype_id = ""
	    	}

	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}

	    	var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}

	    	axios.get(config.api_path+'/users?vendor_id='+vendor_id+'&depot_id='+depot_id+'&status=&email='+this.filter.email+'&firstname='+this.filter.firstname+'&lastname='+this.filter.lastname+'&username='+this.filter.username+'&usertype_id='+usertype_id+'&page='+this.currentPageAll+'&limit=20')
	    	.then(response => {
	    		this.dataListAll = response.data; 
	    		this.dataListAll.data = this.dataListAll.data.map((value, index)=>{
	    			if(value.usertype_id){
	    				value.usertype_id_label = value.usertype.setting_name;
	    			}
	    			else{
	    				value.usertype_id = "N/A";
	    			}
	    			if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name;
	    			}
	    			else{
	    				value.depot_id_label = "All";
	    			}
	    			return value;
	    		}); 
				this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataInactive(){
		this.isLoading=true;
	    	var usertype_id = this.filter.usertype_id;
	    	if(usertype_id == "all" || usertype_id == null){
	    		usertype_id = ""
	    	}
	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
	    	var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	axios.get(config.api_path+'/users?vendor_id='+vendor_id+'&depot_id='+depot_id+'&status=inactive&email='+this.filter.email+'&firstname='+this.filter.firstname+'&lastname='+this.filter.lastname+'&username='+this.filter.username+'&usertype_id='+usertype_id+'&page='+this.currentPageAll+'&limit=20')
	    	.then(response => {
	    		this.dataListInactive = response.data; 
	    		this.dataListInactive.data = this.dataListInactive.data.map((value, index)=>{
	    			if(value.usertype_id){
	    				value.usertype_id_label = value.usertype.setting_name;
	    			}
	    			else{
	    				value.usertype_id = "N/A";
	    			}
	    			if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name;
	    			}
	    			else{
	    				value.depot_id_label = "All";
	    			}
	    			return value;
	    		}); 
				this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    getDataActive(){
			this.isLoading=true;
	    	var usertype_id = this.filter.usertype_id;
	    	if(usertype_id == "all" || usertype_id == null){
	    		usertype_id = ""
	    	}
	    	var depot_id = this.filter.depot_id;
	    	if(depot_id == "all" || depot_id == null){
	    		depot_id = ""
	    	}
	    	var vendor_id = this.filter.vendor_id;
	    	if(vendor_id == "all" || vendor_id == null){
	    		vendor_id = ""
	    	}
	    	axios.get(config.api_path+'/users?vendor_id='+vendor_id+'&depot_id='+depot_id+'&status=active&email='+this.filter.email+'&firstname='+this.filter.firstname+'&lastname='+this.filter.lastname+'&username='+this.filter.username+'&usertype_id='+usertype_id+'&page='+this.currentPageAll+'&limit=20')
	    	.then(response => {
	    		this.dataListActive = response.data; 
	    		this.dataListActive.data = this.dataListActive.data.map((value, index)=>{
	    			if(value.usertype_id){
	    				value.usertype_id_label = value.usertype.setting_name;
	    			}
	    			else{
	    				value.usertype_id = "N/A";
	    			}
	    			if(value.depot_id){
	    				value.depot_id_label = value.depot.setting_name;
	    			}
	    			else{
	    				value.depot_id_label = "All";
	    			}
	    			return value;
	    		}); 
				this.setCount()
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    updateModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = {...item};
	    	if(this.dataParams.depot_id == null){
	    		this.dataParams.depot_id = "all";
	    	}
	    	this.formModal = true;
	    	this.editMode = true;
			this.title = "Update User";

	    },

	    viewModal(item){

	    	this.selectedRow = item;
	    	this.dataParams = item;
	    	this.showViewModal = true; 

	    },

	    deleteRow(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this User?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/users/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		this.getDataActive(); 
			    		this.getDataInactive(); 
			    		Swal.fire({
							title: 'Success!',
							text: "User successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

	    updateGender(data){
	    	this.dataParams.gender = data;
	    },

	   
	    getVendor(){

	    	axios.get(config.api_path+'/company?page=1&limit=1000000')
	    	.then(response => {
	    		this.companyList = response.data;
	    		this.companyList.data = this.companyList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.company_name;
	    			return value;
	    		})
	    	})

	    },

	    getUserType(){
	    	axios.get(config.api_path+'/setting?setting_type=user_type&page=1&limit=10000000')
	    	.then(response => {
	    		this.userTypeList = response.data;
	    		this.userTypeList.data = this.userTypeList.data.map((value, index)=>{
	    			value.value = value.id;
	    			value.label = value.setting_name;
	    			return value;
	    		})
	    	})
        },

	    getDepot(){
				axios.get(config.api_path+'/reference/settings-list',{
					params:{
					setting_type:'depot'
						}
					})
				.then(response => {
				this.depotList = response.data; 
			})

			},

	    updateVendorType(data){
	    	this.dataParams.vendor_type = data;
	    },

	    updatePersonnelType(data){
	    	this.dataParams.personnel_type = data;
	    },

	    updateEmploymentType(data){
	    	this.dataParams.employment_type = data;
	    },

	    updateVendor(data){
	    	this.dataParams.vendor_id = data;
	    },

	    updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    }

 	}
}
</script>

<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput v-model="username" placeholder="Username"/>
        </CCol>
        <CCol lg="3">
          <CInput v-model="email" placeholder="Email"/>
        </CCol>
        <CCol lg="3">
          <CInput  v-model="firstname" placeholder="Firstname"/>
        </CCol>
       
        <CCol lg="3">
          <CInput  v-model="lastname" placeholder="Lastname"/>
        </CCol>
        <CCol lg="3">
          <v-select 
            label="setting_name" 
            :options="userTypeList.data" 
            :reduce="item => item.id"
            v-model="usertype_id"
            placeholder="User Type"
          > 
          </v-select>  
        </CCol>
        <CCol lg="3" class="">
          <v-select 
            :disabled="JSON.parse(window.localStorage.getItem('user_data')).depot_id != null"
            label="setting_name" 
            :options="depotList.data"
            :reduce="item => item.id"
            v-model="depot_id"
            placeholder="Depot"
          > 
          </v-select>
        </CCol>
        <CCol lg="3" class="float-right mt-1">
          <CButton size="sm" color="info" variant="outline" style="margin-right: 10px"  @click="search">Search</CButton>
       
          <CButton size="sm" color="info" variant="outline" @click="clearSearch" style="margin-right: 10px"  >Clear Filter</CButton>
         
        </CCol>
       
      </CRow>
      <!-- <CRow> 
        <CCol lg="3">
          <v-select 
            label="vendor_name" 
            :options="vendorList.data" 
            :reduce="item => item.id"
            v-model="vendor_id"
            placeholder="Vendor"
          > 
          </v-select>  
        </CCol>  
      </CRow> -->
  </div>
</template>

<script>
  import config from '../../config.js';
  import axios from '../../axios';
  import vSelect from 'vue-select'
  import 'vue-select/dist/vue-select.css';
    export default {
        name: "BookingTypeSearch",
        data() {
          return {
            window,
            username: "",
            email: "",
            firstname: "",
            lastname: "",
            usertype_id: "", 
            depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
            vendor_id: "",

            userTypeList: {
              data: []
            },
            depotList: {
              data: []
            },
            vendorList: {
              data: []
            }

          }
        },
      mounted() {
        this.getUserType();
        this.getDepot();
  
      },
      components: {vSelect},
      methods: {
        search() {
          const data = {
            username: this.username,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            usertype_id: this.usertype_id, 
            depot_id: this.depot_id, 
            vendor_id: this.vendor_id, 
          }
          this.$emit("depot-search-query", data)
        },
        clearSearch() {
          this.username = "";
          this.email = "";
          this.firstname = "";
          this.lastname = "";
          this.usertype_id = "";
          this.vendor_id = "";
          this.depot_id = JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "";

          const data = {
            username: this.username,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            usertype_id: this.usertype_id, 
            depot_id: this.depot_id, 
            vendor_id: this.vendor_id, 
          }
          this.$emit("depot-search-query", data)
        }, 
        getUserType(){

          axios.get(config.api_path+'/setting?setting_type=user_type&page=1&limit=10000000')
          .then(response => {
            this.userTypeList = response.data;
            this.userTypeList.data = this.userTypeList.data.map((value, index)=>{
              value.value = value.id;
              value.label = value.setting_name;
              return value;
            })
            this.userTypeList.data.unshift({
              id: "all",
              setting_name: "All"
            })
          })

        },
        getDepot(){
            axios.get(config.api_path+'/reference/settings-list',{
            params:{
            setting_type:'depot'
              }
            })
          .then(response => {
          this.depotList = response.data; 
        })

     }, 
      
      }
    }
</script>

<style scoped>

</style>
